import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
  useAuth,
} from 'reactfire';
import {
  QueryConstraint,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import Card from '@mui/material/Card';
// import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import IdCell from './components/IdCell';
import CustomerCell from './components/CustomerCell';
import SubscriptionPlanCell from './components/SubscriptionPlanCell';
import {
  Column,
  ColumnInstance,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import DefaultCell from './components/DefaultCell';
import { useNavigate } from 'react-router-dom';
import ActionCell from './components/ActionCell';
import { CardContent, CardHeader, CircularProgress } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { stat } from 'fs';
import { GenericResponse, PortalUser, ServiceProvider } from 'firebaseModels';
import { usePortalUser } from 'routes';
import { ReceiptOutlined, SummarizeOutlined } from '@mui/icons-material';
type ProviderReportMap = { [key: string]: ServiceProvider };

type ProviderReportType = {
  success: boolean;
  providers: ProviderReportMap;
};

type Props = {
  trial?: boolean;
};
function ProviderList({ trial = false }: Props): JSX.Element {
  const navigate = useNavigate();
  // const [menu, setMenu] = useState(null);
  // const [report, setReport] = useState(null);
  // const [reportStatus, setReportStatus] = useState('idle'); // ['idle', 'loading', 'success', 'error']
  const [isDeleting, setIsDeleting] = useState(false);

  const { currentUser } = useAuth();
  const email = useMemo(
    () => currentUser?.providerData[0]?.email || '',
    [currentUser]
  );

  const { portalUser } = usePortalUser(email);

  const isReseller =
    portalUser && (portalUser as PortalUser).level === 'reseller';

  const firestore = useFirestore();
  const providersCollection = useMemo(
    () => collection(firestore, 'ServiceProvider'),
    [firestore]
  );

  const providersQuery = useMemo(
    () =>
      query(
        providersCollection,
        // trial
        //   ? where('trialProvider', '==', true)
        //   : where('trialProvider', '==', false),
        isReseller
          ? where('resellerId', '==', portalUser?.provider.id)
          : undefined,

        trial ? orderBy('created', 'desc') : orderBy('name', 'asc')
      ),
    [isReseller, portalUser, providersCollection, trial]
  );

  const reportsCollection = useMemo(
    () => collection(firestore, 'Reports'),
    [firestore]
  );
  // get the latest report of type "provider"

  let reportConstraints: QueryConstraint = useMemo(
    () => where('type', '==', 'provider'),
    []
  );

  const reportQuery = useMemo(
    () =>
      query(
        reportsCollection,
        reportConstraints,
        orderBy('created', 'desc'),
        limit(1)
      ),
    [reportConstraints, reportsCollection]
  );

  const reportData = useFirestoreCollectionData(reportQuery, {
    idField: 'id',
  });
  const { status: reportStatus, data: reports } = reportData;

  const report = useMemo(() => {
    if (reportStatus === 'success' && reports && reports.length > 0) {
      // console.log('report timestamp', reports[0].created.toDate());
      return reports[0].providers as ProviderReportMap;
    }
    return null;
  }, [reportStatus, reports]);

  const functions = useFunctions();

  const delete_provider = useCallback(() => {
    return httpsCallable(functions, 'deleteserviceprovider');
  }, [functions]);

  // const get_provider_report = useCallback(() => {
  //   return httpsCallable<void, ProviderReportType>(
  //     functions,
  //     'getproviderreport'
  //   );
  // }, [functions]);

  // const openMenu = (event: any) => setMenu(event.currentTarget);
  // const closeMenu = () => setMenu(null);

  const [providers, setProviders] = useState<Array<ServiceProvider> | null>(
    null
  );
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');

  useEffect(() => {
    // subscribe to providers collection
    setStatus('loading');
    const unsubscribe = onSnapshot(providersQuery, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as ServiceProvider;
      });
      setProviders(data);
      setStatus('success');
    });
    return () => unsubscribe();
  }, [providersQuery]);

  const columns = useMemo<Array<Column<ServiceProvider>>>(() => {
    let col_builder: Array<Column<ServiceProvider>> = [
      {
        Header: '#',
        accessor: 'publicId',
        sortType: 'number',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
    ];
    if (trial) {
      col_builder.push({
        Header: 'created',
        accessor: 'createdAsDate',
        sortType: 'datetime',
        Cell: ({ value }: any) => (
          <DefaultCell value={value.toLocaleDateString()} />
        ),
      });
    } else {
      col_builder.push({
        Header: 'invoicing',
        accessor: (row: any) => ({ id: row.id }),
        Cell: ({ value: { id } }: { value: { id: string } }) =>
          !trial && (
            <MDButton
              color="info"
              onClick={() => {
                navigate(`/providers/${id}/invoices`);
              }}
            >
              <ReceiptOutlined /> invoicing
            </MDButton>
          ),
      });
      col_builder.push({
        Header: 'reports',
        accessor: (row: any) => ({ id: row.id }),
        Cell: ({ value: { id } }: { value: { id: string } }) =>
          !trial && (
            <MDButton
              color="info"
              startIcon={<SummarizeOutlined />}
              onClick={() => {
                navigate(`/providers/${id}/reports`);
              }}
            >
              reports
            </MDButton>
          ),
      });
    }
    return [
      ...col_builder,
      {
        Header: 'name',
        accessor: 'name',
        Cell: ({ value }: any) => <CustomerCell name={value} />,
      },
      {
        Header: 'plan',
        accessor: 'subscriptionPlan',
        Cell: ({ value }: any) =>
          value ? (
            <SubscriptionPlanCell reference={value} />
          ) : (
            <span>none</span>
          ),
      },
      {
        Header: 'reseller',
        accessor: 'resellerName',
        Cell: ({ value }: any) =>
          value ? <DefaultCell value={value} /> : <span></span>,
      },
      {
        Header: 'n. lic',
        accessor: 'nrLicences',
        align: 'right',
        type: 'number',
        width: 70,
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'max o1',
        accessor: 'maxLicences',
        align: 'right',
        type: 'number',
        width: 70,
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'used',
        accessor: 'nrLicencesUsed',
        align: 'right',
        type: 'number',
        width: 70,
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'unused',
        accessor: 'nrLicencesUnused',
        align: 'right',
        type: 'number',
        width: 70,
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'actions',
        accessor: (row: any) => ({ id: row.id, name: row.name }),
        align: 'right',
        disableSortBy: true,
        Cell: ({
          value: { id, name },
        }: {
          value: { id: string; name: string };
        }) => (
          <ActionCell
            name={name}
            deleteDisabled={!trial && report && report[id]?.nrLicences > 0}
            isDeleting={isDeleting}
            onEditClick={() => {
              navigate('/providers/edit/' + (trial ? 'trial/' : '') + `${id}`);
            }}
            onDeleteConfirm={() => {
              setIsDeleting(true);
              delete_provider()({
                providerId: id,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
                .then((result) => {
                  setIsDeleting(false);
                })
                .catch(() => {
                  setIsDeleting(false);
                });
            }}
          />
        ),
      },
    ];
  }, [delete_provider, isDeleting, navigate, report, trial]);

  const data = useMemo<Array<ServiceProvider>>(
    () =>
      providers
        ?.filter((p) => p.trialProvider === trial)
        .map((p) => {
          const { created } = p as ServiceProvider;
          return {
            ...p,
            createdAsDate: created.toDate(),
            nrLicences: report ? report[p.id]?.nrLicences : 0,
            nrLicencesUsed: report ? report[p.id]?.nrLicencesUsed : 0,
            nrLicencesUnused: report ? report[p.id]?.nrLicencesUnused : 0,
          } as ServiceProvider;
        }) || [],
    [providers, report, trial]
  );

  const tableInstance = useTable<ServiceProvider>(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          {trial === false && (
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate('/providers/new');
              }}
            >
              new provider
            </MDButton>
          )}
          <MDBox display="flex">
            {/* <MDButton
              variant={menu ? 'contained' : 'outlined'}
              color="dark"
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu} */}
            {/* <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        <Card>
          <CardHeader title={trial ? 'Trial Providers' : 'Providers'} />
          <CardContent>
            {status === 'loading' ? (
              <CircularProgress />
            ) : (
              <DataTable
                key="provider-list"
                tableInstance={tableInstance}
                entriesPerPage={{
                  defaultValue: 50,
                  entries: [50, 100, 200, 500, 1000],
                }}
                canSearch
              />
            )}
          </CardContent>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProviderList;
