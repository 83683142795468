import { useEffect, useMemo, useState } from 'react';
import validator from 'validator';

// @mui material components
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';

// NewProvider page components
import FormField from './FormField';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, orderBy, query } from 'firebase/firestore';
import { SubscriptionPlan } from 'firebaseModels';
import { ProviderData } from './NewProvider';
import { CircularProgress } from '@mui/material';
import { CountryType, countries } from 'consts';

function ProviderInfo({
  providerData,
  setProviderData,
  mainAddressIsValid,
  invoiceAddressIsValid,
  phoneNumberIsValid,
  companyPhoneNumberIsValid,
}: {
  providerData?: ProviderData;
  setProviderData: Function;
  invoiceAddressIsValid: boolean;
  mainAddressIsValid: boolean;
  phoneNumberIsValid: boolean;
  companyPhoneNumberIsValid: boolean;
}): JSX.Element {
  const firestore = useFirestore();
  const plansCollection = useMemo(
    () => collection(firestore, 'SubscriptionPlan'),
    [firestore]
  );
  const plansQuery = useMemo(
    () => query(plansCollection, orderBy('name', 'asc')),
    [plansCollection]
  );
  const { status, data: plans } = useFirestoreCollectionData(plansQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!providerData.subscriptionPlan && plans && plans?.length > 0) {
      const { planId } = providerData;
      const subscriptionPlan = planId
        ? plans.find((p) => p.id === planId)
        : plans[0];
      setProviderData({
        ...providerData,
        subscriptionPlan,
      });
    }
  }, [providerData, plans, setProviderData]);

  if (status === 'loading' || !providerData) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress />
      </MDBox>
    );
  }
  return (
    <MDBox pt={4} pb={3} px={3}>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <MDTypography variant="h6" gutterBottom>
            Organization Information
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <FormField
            type="text"
            label="Name of Company or Organization"
            name="organizationName"
            value={providerData.name || ''}
            error={providerData.name?.length < 2}
            helperText="Company or organization name is required"
            success={providerData.name}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            type="text"
            label="Address"
            name="address"
            value={providerData.address || ''}
            success={providerData.address}
            error={!mainAddressIsValid && !providerData.address}
            helperText={
              !mainAddressIsValid && !providerData.address
                ? 'Address is required'
                : ''
            }
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                address: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            type="text"
            label="Address 2"
            name="address2"
            value={providerData.address2 || ''}
            success={
              !providerData.invoice_address_is_same_as_main
                ? undefined
                : providerData.address2
            }
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                address2: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type="text"
            label="Zip Code"
            name="zipCode"
            value={providerData.zipCode || ''}
            success={providerData.zipCode}
            error={!mainAddressIsValid && !providerData.zipCode}
            helperText={
              !mainAddressIsValid && !providerData.zipCode
                ? 'Zip code is required'
                : ''
            }
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                zipCode: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type="text"
            label="City"
            name="city"
            value={providerData.city || ''}
            success={providerData.city}
            error={!mainAddressIsValid && !providerData.city}
            helperText={
              !mainAddressIsValid && !providerData.city
                ? 'City is required'
                : ''
            }
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                city: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type="text"
            label="State"
            name="state"
            value={providerData.state || ''}
            success={providerData.state?.length > 1}
            error={!mainAddressIsValid && !(providerData.state?.length > 1)}
            helperText={
              !mainAddressIsValid && !(providerData.state?.length > 1)
                ? 'State is required'
                : ''
            }
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                state: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={countries}
            autoHighlight
            value={providerData.country || null}
            isOptionEqualToValue={(option, value) => {
              return option.code === value.code;
            }}
            onChange={(event: any, newValue: CountryType | null) => {
              setProviderData({
                ...providerData,
                country: newValue,
              });
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <FormField
                {...params}
                label="Country"
                name="country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                error={providerData.country === null}
                helperText="Country is required"
                success={providerData.country !== null}
                sx={{
                  '& > div': {
                    '& > input': {
                      cursor: 'pointer',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="Phone Number"
            name="phone"
            value={providerData.phone || ''}
            error={!companyPhoneNumberIsValid}
            helperText={
              !companyPhoneNumberIsValid ? 'Invalid phone number' : ''
            }
            success={companyPhoneNumberIsValid}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                phone: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="Email"
            name="email"
            error={providerData.email && !validator.isEmail(providerData.email)}
            helperText={
              providerData.email && !validator.isEmail(providerData.email)
                ? 'Invalid email'
                : ''
            }
            success={
              providerData.email?.length === 0 ||
              validator.isEmail(providerData.email)
            }
            value={providerData.email || ''}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                email: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <MDTypography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Subscription Plan
          </MDTypography>
        </Grid>
        <Grid item xs={6}>
          <MDBox>
            <Autocomplete
              disableClearable
              loading={!plans?.length}
              options={(plans || []) as SubscriptionPlan[]}
              getOptionLabel={(option: SubscriptionPlan) => option.name}
              renderInput={(params) => {
                return <FormField {...params} label="Subscription Plan" />;
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              onChange={(e: any, value: SubscriptionPlan) => {
                setProviderData({
                  ...providerData,
                  subscriptionPlan: value,
                });
              }}
              value={providerData.subscriptionPlan}
              defaultValue={plans[0] as SubscriptionPlan}
            />
          </MDBox>
        </Grid>
        <Grid item xs={3}>
          <FormField
            type="number"
            label="Max O1 Licenses"
            name="maxLicenses"
            value={providerData.maxLicences || ''}
            placeholder="unlimited"
            success={providerData.maxLicences}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                maxLicences: e.target.value ? parseInt(e.target.value) : null,
              })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormField
            type="number"
            label="Max O3 Licenses"
            name="maxLicensesO3"
            placeholder="unlimited"
            value={providerData.maxLicencesO3 || ''}
            success={providerData.maxLicencesO3}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                maxLicencesO3: e.target.value ? parseInt(e.target.value) : null,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Invoicing Information
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="Invoice Email"
            name="invoice_email"
            value={providerData.invoice_email || ''}
            error={
              providerData?.invoice_email &&
              !validator.isEmail(providerData?.invoice_email)
            }
            helperText={
              providerData?.invoice_email &&
              !validator.isEmail(providerData?.invoice_email)
                ? 'Invalid email'
                : ''
            }
            success={
              providerData.invoice_email?.length > 0 &&
              validator.isEmail(providerData.invoice_email)
            }
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                invoice_email: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="VAT Number"
            name="invoice_vat_number"
            value={providerData.invoice_vat_number || ''}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                invoice_vat_number: e.target.value,
              })
            }
          />
        </Grid>

        {/* Electronic invoicing information with visual indication */}
        <Grid item xs={12}>
          <MDBox
            mt={2}
            mb={2}
            p={2}
            border="1px solid"
            borderColor="grey.300"
            borderRadius="4px"
            bgcolor="grey.100"
          >
            <MDTypography variant="subtitle2" fontWeight="medium" mb={2}>
              Electronic Invoicing Information (Sähköisen laskutuksen tiedot)
            </MDTypography>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormField
                  type="text"
                  label="Electronic Invoice Name"
                  name="electronic_invoice_name"
                  value={providerData.electronic_invoice_name || ''}
                  onChange={(e: any) =>
                    setProviderData({
                      ...providerData,
                      electronic_invoice_name: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  type="text"
                  label="Network Invoice Address"
                  name="network_invoice_address"
                  value={providerData.network_invoice_address || ''}
                  onChange={(e: any) =>
                    setProviderData({
                      ...providerData,
                      network_invoice_address: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  type="text"
                  label="Operator ID"
                  name="operator_id"
                  value={providerData.operator_id || ''}
                  onChange={(e: any) =>
                    setProviderData({
                      ...providerData,
                      operator_id: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  type="text"
                  label="Intermediary ID"
                  name="intermediary_id"
                  value={providerData.intermediary_id || ''}
                  onChange={(e: any) =>
                    setProviderData({
                      ...providerData,
                      intermediary_id: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>

        <Grid item xs={12}>
          <MDBox display="flex" alignItems="center" ml={-1} mt={3} mb={2}>
            <Checkbox
              id="invoicing_checkbox"
              checked={providerData.invoice_address_is_same_as_main}
              disabled={loading || success}
              onChange={(e: any) =>
                setProviderData({
                  ...providerData,
                  invoice_address_is_same_as_main: e.target.checked,
                })
              }
            />
            <MDTypography
              component="label"
              htmlFor="invoicing_checkbox"
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
            >
              &nbsp;&nbsp;Use the main address for invoicing
            </MDTypography>
          </MDBox>
        </Grid>
        {!providerData.invoice_address_is_same_as_main && (
          <>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Name of Company or Organization for Invoicing"
                name="invoice_organization_name"
                value={providerData.invoice_organization_name || ''}
                error={
                  !invoiceAddressIsValid &&
                  !providerData.invoice_organization_name
                }
                helperText={
                  !invoiceAddressIsValid &&
                  !providerData.invoice_organization_name
                    ? 'Name is required'
                    : ''
                }
                success={providerData.invoice_organization_name}
                onChange={(e: any) =>
                  setProviderData({
                    ...providerData,
                    invoice_organization_name: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Address for Invoicing"
                name="invoice_address"
                value={providerData.invoice_address || ''}
                error={!invoiceAddressIsValid && !providerData.invoice_address}
                helperText={
                  !invoiceAddressIsValid && !providerData.invoice_address
                    ? 'Address is required'
                    : ''
                }
                success={providerData.invoice_address}
                onChange={(e: any) =>
                  setProviderData({
                    ...providerData,
                    invoice_address: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Address 2 for Invoicing"
                name="invoice_address2"
                value={providerData.invoice_address2 || ''}
                onChange={(e: any) =>
                  setProviderData({
                    ...providerData,
                    invoice_address2: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Zip Code for Invoicing"
                name="invoice_zipCode"
                value={providerData.invoice_zipCode || ''}
                error={!invoiceAddressIsValid && !providerData.invoice_zipCode}
                helperText={
                  !invoiceAddressIsValid && !providerData.invoice_zipCode
                    ? 'Zip code is required'
                    : ''
                }
                success={providerData.invoice_zipCode}
                onChange={(e: any) =>
                  setProviderData({
                    ...providerData,
                    invoice_zipCode: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="City for Invoicing"
                name="invoice_city"
                value={providerData.invoice_city || ''}
                error={!invoiceAddressIsValid && !providerData.invoice_city}
                helperText={
                  !invoiceAddressIsValid && !providerData.invoice_city
                    ? 'City is required'
                    : ''
                }
                success={providerData.invoice_city}
                onChange={(e: any) =>
                  setProviderData({
                    ...providerData,
                    invoice_city: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="State for Invoicing"
                name="invoice_state"
                value={providerData.invoice_state || ''}
                error={
                  !invoiceAddressIsValid &&
                  !(providerData.invoice_state?.length > 1)
                }
                helperText={
                  !invoiceAddressIsValid &&
                  !(providerData.invoice_state?.length > 1)
                    ? 'State is required'
                    : ''
                }
                success={providerData.invoice_state?.length > 1}
                onChange={(e: any) =>
                  setProviderData({
                    ...providerData,
                    invoice_state: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={countries}
                value={providerData.invoice_country || null}
                onChange={(event: any, newValue: CountryType | null) => {
                  setProviderData({
                    ...providerData,
                    invoice_country: newValue,
                  });
                }}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Country for Invoicing"
                    name="invoiceCountry"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    error={
                      !invoiceAddressIsValid &&
                      providerData.invoice_country === null
                    }
                    helperText={
                      !invoiceAddressIsValid &&
                      providerData.invoice_country === null
                        ? 'Country is required'
                        : ''
                    }
                    success={providerData.invoice_country !== null}
                    sx={{
                      '& > div': {
                        '& > input': {
                          cursor: 'pointer',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <MDTypography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Contact Person
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="First Name"
            name="contact_first_name"
            value={providerData.contact_first_name || ''}
            success={providerData.contact_first_name}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                contact_first_name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="Last Name"
            name="contact_last_name"
            value={providerData.contact_last_name || ''}
            success={providerData.contact_last_name}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                contact_last_name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            type="text"
            label="Role in Organization"
            name="contact_organization_role"
            value={providerData.contact_organization_role || ''}
            success={providerData.contact_organization_role}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                contact_organization_role: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="Email"
            name="contact_email"
            error={
              !providerData.contact_email ||
              !validator.isEmail(providerData.contact_email)
            }
            helperText={
              !providerData.contact_email ||
              !validator.isEmail(providerData.contact_email)
                ? 'Invalid email'
                : ''
            }
            success={
              providerData.contact_email?.length > 0 &&
              validator.isEmail(providerData.contact_email)
            }
            value={providerData.contact_email || ''}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                contact_email: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="Phone Number"
            name="contact_phone"
            value={providerData.contact_phone || ''}
            error={!phoneNumberIsValid}
            helperText={!phoneNumberIsValid ? 'Invalid phone number' : ''}
            success={phoneNumberIsValid}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                contact_phone: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Remarks
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            value={providerData.description || ''}
            onChange={(e: any) =>
              setProviderData({
                ...providerData,
                description: e.target.value,
              })
            }
            multiline
            maxRows={4}
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {error && (
            <>
              <MDTypography variant="h6" color="error" sx={{ mt: 3 }}>
                Error
              </MDTypography>
              <MDTypography variant="body1" color="error">
                {error}
              </MDTypography>
            </>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ProviderInfo;
