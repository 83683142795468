/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  SyntheticEvent,
} from 'react';

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  TableInstance,
  Row,
} from 'react-table';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from 'examples/Tables/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'examples/Tables/DataTable/DataTableBodyCell';

// Declaring props types for DataTable
interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  tableInstance: TableInstance<any>;
  pagination?: {
    variant: 'contained' | 'gradient';
    color:
      | 'primary'
      | 'secondary'
      | 'info'
      | 'success'
      | 'warning'
      | 'error'
      | 'dark'
      | 'light';
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
}

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  tableInstance,
  pagination,
  isSorted,
  noEndBorder,
}: Props): JSX.Element {
  let defaultValue = entriesPerPage ? entriesPerPage.defaultValue : 10;
  let entries = entriesPerPage ? entriesPerPage.entries : [10, 25, 50, 100];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: TableInstance = tableInstance;

  const [preparedPage, setPreparedRows] = useState<Row<any>[] | null>(null);

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue), [defaultValue, setPageSize]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = useCallback(
    (_: SyntheticEvent<Element, Event>, value: number) => setPageSize(value),
    [setPageSize]
  );

  // Render the paginations
  const renderPagination = useMemo(
    () =>
      pageOptions.map((option: number) => (
        <MDPagination
          item
          key={option}
          onClick={() => gotoPage(Number(option))}
          active={pageIndex === option}
        >
          {option + 1}
        </MDPagination>
      )),
    [pageOptions, pageIndex, gotoPage]
  );

  // Handler for the input to set the pagination index
  const handleInputPagination = useCallback(
    ({ target: { value } }: { target: { value: number } }) =>
      value > pageOptions.length || value < 0
        ? gotoPage(0)
        : gotoPage(Number(value)),
    [gotoPage, pageOptions.length]
  );

  // Customized page options starting from 1
  const customizedPageOptions = useMemo(
    () => pageOptions.map((option: number) => option + 1),
    [pageOptions]
  );

  // Setting value for the pagination input
  const handleInputPaginationValue = useCallback(
    ({ target: value }: { target: { value: number } }) =>
      gotoPage(Number(value.value - 1)),
    [gotoPage]
  );

  // Search input value state
  const [search, setSearch] = useState(globalFilter || '');

  // Search input state handle
  const updateFilter = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 100);

  const onSearchChange = useCallback(
    ({ currentTarget }: any) => {
      const { value } = currentTarget;
      setSearch(value);
      setTimeout(() => {
        updateFilter(value);
      }, 200);
    },
    [updateFilter]
  );

  // A function that sets the sorted value for the table
  const setSortedValue = useCallback(
    (column: any) => {
      let sortedValue;

      if (isSorted && column.isSorted) {
        sortedValue = column.isSortedDesc ? 'desc' : 'asce';
      } else if (isSorted) {
        sortedValue = 'none';
      } else {
        sortedValue = false;
      }

      return sortedValue;
    },
    [isSorted]
  );

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd: number;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const tableProps = useMemo(() => getTableProps(), [getTableProps]);
  const tableBodyProps = useMemo(
    () => getTableBodyProps(),
    [getTableBodyProps]
  );

  useEffect(() => {
    setPreparedRows(() => {
      return page.map((row) => {
        prepareRow(row);
        return row;
      });
    });
  }, [page, prepareRow]);

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={defaultValue}
                options={entries}
                getOptionLabel={(option) => `${option}`}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={setEntriesPerPage}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={onSearchChange}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...tableProps}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, idx: any) => (
            <DataTableHeaderRow
              key={`dthr-${idx}`}
              headerGroup={headerGroup}
              isSorted={isSorted}
              setSortedValue={setSortedValue}
            />
          ))}
        </MDBox>
        <TableBody {...tableBodyProps}>
          {preparedPage?.map((row: any, idx: any) => (
            <DataTableRow
              key={`dtr-${idx}`}
              row={row}
              noEndBorder={noEndBorder}
              nRows={rows.length}
            />
          ))}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : 'gradient'}
            color={pagination.color ? pagination.color : 'info'}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: 'number',
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ['5', '10', '15', '20', '25'] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;

type DataTableHeaderRowProps = {
  headerGroup: any;
  isSorted: boolean;
  setSortedValue: (column: any) => string | boolean;
};

function DataTableHeaderRow(props: DataTableHeaderRowProps) {
  const { headerGroup, isSorted, setSortedValue } = props;

  const row = useMemo(
    () => (
      <TableRow {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column: any, idx: any) => (
          <DataTableHeadCell
            key={`dthc-${idx}`}
            {...column.getHeaderProps(
              isSorted && column.getSortByToggleProps()
            )}
            width={column.width ? column.width : 'auto'}
            align={column.align ? column.align : 'left'}
            sorted={setSortedValue(column)}
          >
            {column.render('Header')}
          </DataTableHeadCell>
        ))}
      </TableRow>
    ),
    [headerGroup, isSorted, setSortedValue]
  );

  return row;
}

type DataTableRowProps = {
  row: Row<any>;
  noEndBorder: boolean;
  nRows: number;
};

function DataTableRow(props: DataTableRowProps) {
  const { row, noEndBorder, nRows } = props;

  const rowProps = useMemo(() => row.getRowProps(), [row]);

  const ret = useMemo(
    () => (
      <TableRow {...rowProps}>
        {row.cells.map((cell: any, idx: any) => (
          <DataTableBodyCell
            key={`dtbc-${idx}`}
            noBorder={noEndBorder && nRows - 1 === idx}
            align={cell.column.align ? cell.column.align : 'left'}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </DataTableBodyCell>
        ))}
      </TableRow>
    ),
    [rowProps, row, noEndBorder, nRows]
  );
  return ret;
}
