/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Checkbox from '@mui/material/Checkbox';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Declaring props types for IdCell
interface Props {
  onChange?: SwitchBaseProps['onChange'];
  checked?: boolean;
}

function CheckboxCell({ onChange, checked }: Props): JSX.Element {
  return (
    <MDBox
      display="flex"
      sx={{
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Checkbox defaultChecked={checked} onChange={onChange} />
    </MDBox>
  );
}

// Declaring default props for IdCell
CheckboxCell.defaultProps = {
  checked: false,
};

export default CheckboxCell;
